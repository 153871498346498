const Footer = () => {
  return (
    <div className="flex flex-row py-8 bg-neutral">
      <div className="flex phone-s:flex-col laptop:flex-row laptop:justify-between phone-s:gap-5 laptop:gap-4 mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl">
        <div className="flex relative items-center justify-center w-32 h-32">
          <img src="/dhi.png" className="App-logo" alt="logo" />
        </div>

        <div className="flex flex-col gap-3 text-base">
          <div className="font-bold">DIVHUBINTER POLRI</div>
          <div className="">
            Jl. Trunojoyo 3 Mabes POLRI, Jakarta, Indonesia.
          </div>
          <div className="">Phone (Jakarta) : +6221 7393650</div>
          <div className="">E-mail: ncb-jakarta@interpol.go.id</div>
        </div>
        <div className="flex flex-col gap-3 text-base">
          <div className="font-bold">PROFIL DIVHUBINTER POLRI</div>
          <div className="hover:text-red-600">
            <a href="/visi-misi">Visi dan Misi</a>
          </div>
          <div className="hover:text-red-600">
            <a href="/profil">Profil</a>
          </div>
          <div className="hover:text-red-600">
            <a href="/struktur-organisasi">Struktur Organisasi</a>
          </div>
          <div className="hover:text-red-600">
            <a href="/tugas-fungsi">Tugas dan Fungsi</a>
          </div>
          <div className="hover:text-red-600">Tim Koordinasi Interpol</div>
        </div>
        <div className="flex flex-col gap-3 text-base">
          <div className="font-bold">WEBLINKS</div>
          <div className="hover:text-red-600">
            <a href="https://polri.go.id">POLRI</a>
          </div>
          <div className="hover:text-red-600">DIVHUBINTER</div>
          <div className="hover:text-red-600">Biro Misi Internasional</div>
          <div className="hover:text-red-600">
            <a href="https://kemlu.go.id/portal/id">Kementerian Luar Negeri</a>
          </div>
          <div className="hover:text-red-600">
            <a href="https://www.kemenkumham.go.id/">
              Kementerian Hukum dan Hak Asasi Manusia
            </a>
          </div>
          <div className="hover:text-red-600">
            <a href="https://asean.org/">ASEAN</a>
          </div>
          <div className="hover:text-red-600">
            <a href="http://www.aseanapol.org/">ASEANAPOL</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
